@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700|Source+Sans+Pro:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
*
* COLOR VARIABLES
*
*/

:root {

  --alabaster: #fafafa;
	--thunder: #212121;
  --marigold-yellow: #fce373;

  font-size: 100%;
}


/*
*
* BASE STYLES
*
*/

html, body, #root, .app {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #fce373;
  background: var(--marigold-yellow);
  color: #212121;
  color: var(--thunder);
}

main {
  padding: 6rem 0 2rem;
  width: 100%;
  display: flex;
	flex-flow: row wrap;
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  /* background: var(--thunder); */
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  /* background: var(--thunder); */
}


/*
*
* HOMEPAGE
*
*/

/* Intro
   ========================================================================== */

.intro {
  margin: 0 auto;
  width: 66%;
}

.intro__name {
  margin: 0 0 2rem 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 5.8rem;
}

.intro__about {
  font-family: 'Open Sans', sans-serif;
  margin: 0 0 2rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.intro__links {
  margin: 0 0 1rem 0;
  font-size: 1rem;

}

.intro__links > a {
  margin: 0 1rem 0 0;
}

.dribble-icon__ball-lines {
  fill: #fce373;
  fill: var(--marigold-yellow);
}



/*
*
* MEDIA QUERIES
*
*/

/* Desktop
   ========================================================================== */

@media only screen and (min-width: 1200px){
  .intro {
    width: 49.5rem;
  }

}

/* Tablet
   ========================================================================== */

@media only screen and (min-width: 672px) and (max-width: 1087px) {


}

/* Mobile
   ========================================================================== */
   @media only screen and (max-width: 671px) {

     .intro {
       margin: 0;
       width: 100%;
     }

     .intro__name {
       font-size: 3.5rem;
     }
   }

@media only screen and (min-width: 400px) and (max-width: 671px) {

  main {
    padding: 2rem 2rem 0;
  }
}

@media only screen and (max-width: 399px) {

  main {
    padding: 2rem 1rem 0;
  }

}

